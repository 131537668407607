<template>
  <div class="bind-phone">
    <div class="bind-phone-title" @click="$router.go(-1)">
      绑定新手机
    </div>
    <el-form
        :model="formData"
        ref="LXForm"
        size="small"
        :inline="true"
        class="my-form"
        :label-position="'top'"
    >
      <el-row style="text-align: left">
        <el-col class="flex_center" :span="24">
          <el-form-item label="新手机号码">
            <el-input
                v-model="formData.phone"
                placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="bind-phone-title-code" >
        <el-col class="" :span="16" style="margin-right: 1rem;">
          <el-form-item label="验证码">
            <el-input
                v-model="formData.code"
                placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col class="flex_center" :span="8">
          <el-form-item label="">
            <div class="flex_center_a" @click="getCode()">获取验证码
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="apply" @click="clickSave">保存</div>
    </el-form>
  </div>
</template>

<script>
import {httpGet,httpPost,httpNPPost } from "@/utils/httpRequest";
export default {
  name: "BindPhone",
  data() {
    return {
      labelPosition: "top",
      formData: {
        phone: "",
        code: "",
      }
    }
  },
  methods: {
      // 获取验证码
      getCode(){
        httpPost("/captcha/send",
        {
          phone: this.formData.phone
        },
        (res)=>{
          // console.log(res)
        },
        (err)=>{
           ;
          throw err
        })
    },
    clickSave() {
      // console.log(this.formData.phone.length)
      if(this.formData.phone.length==11){
        httpGet("/captcha/contrast",this.formData,
          (res)=>{
            // console.log(res)
            httpNPPost("/app/app-user/update-phone?phone="+this.formData.phone,
            (res)=>{
              this.$message.success("更改手机成功")
              // console.log(res)
            },(err)=>{
               
              throw err
            })
            this.$router.push({ name: 'SafeCenter'});
          },
          (err)=>{
             ;
            throw err 
          }
        )
      }else{
        this.$message.error("手机号格式错误")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bind-phone {
  padding: 0 2rem;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .apply {
    width: 20.7143%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
  }

  .my-form {
    padding: 1.25rem 2.625rem;
    width: 20.75rem;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    height: 3.25rem;
    border-top: 0.0625rem solid #EDEFF2;
    align-items: center;
  }

  .bind-phone-title {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }

  .bind-phone-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }
  .bind-phone-title-code{
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
      .flex_center_a{width: 5.75rem;
      height: 2rem;
      background: #1371E7;
      box-shadow: 0 0.25rem 0.375rem 0 rgba(19,113,231,0.08);
      border-radius: 10px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      }
    }

  ::v-deep .el-form-item {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  ::v-deep .el-form-item__label {
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
  }

  ::v-deep .el-input__inner {
    line-height: 2rem;
    height: 2rem;
  }
}
</style>